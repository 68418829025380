import React from "react";
import { Col, Row } from "react-bootstrap";
import { imageURL2 } from "../../images";
import GlobalBreadcrumb from "../common/global-breadcrumb";
import { CustomContainer } from "../common/global-display";

const RightMetal = () => {
  return (
    <>
      <GlobalBreadcrumb subTitle="Find Right Metal" />

      {/* Begin:: Hero section */}
      <CustomContainer className="mt-0 mb-0 ringStyle-hero">
        <Row className="justify-content-between align-items-center">
          <Col lg="5" md="6">
            <h1>Find the Right Metal</h1>
            <h4 className="mt-4 mb-lg-5 mb-4">
              The Ultimate Guide to Metals for your Jewelry
            </h4>
            <h3 className="fst-italic">
              The type, color and karat of the metal you choose, affects the way
              your gems show face up in the piece of jewelry, and that's what
              we'll cover throughout this article: how to pick the ideal metal
              for your jewelry.
            </h3>
          </Col>
          <Col lg="6" md="6" className="text-center d-none d-md-block">
            <img src={imageURL2.image_108} className="img-fluid" alt="Images" />
          </Col>
        </Row>
      </CustomContainer>
      {/* Begin:: Hero section */}

      {/* Begin:: History of engagement ring */}
      <CustomContainer className=" history-box">
        <Row className="gx-lg-5 align-items-center">
          <Col lg="4" className="d-none d-lg-block">
            <img
              src={imageURL2.image_109}
              className="img-fluid rounded-2"
              alt="Images"
            />
          </Col>
          <Col lg="8">
            <h2 className="heading-50 mb-lg-5 mb-4 fst-italic">
              What color of gold will suit you best?
            </h2>
            <p className="h5">
              Every piece of jewelry has a metal component that accentuates the
              wonderful contrast between the piece, the gems it holds, and your
              skin. Some metals look better on certain skin tones than others.
              Some metals highlight certain gemstones better than others. So,
              let's go over each metal type and how to wear it in the best way
              possible.
              <br /> <br />A good rule of thumb is that people with cooler skin
              tones appear best in light metals, such as platinum and white
              gold. Rose and yellow gold jewelry looks best on warm complexions.
              Those with neutral skin tones will look great in any white, rose,
              and yellow metals.
            </p>
          </Col>
        </Row>
      </CustomContainer>
      {/* End:: History of engagement ring */}

      {/* Begin:: Discover Collection */}
      <CustomContainer className="">
        <h2 className="heading-55 mb-4 text-center fst-italic">
          What are the different gold colors available?
        </h2>
        <p className="text-center col-10 mx-auto">
          Gold is the most non-reactive of all the metals, which means that it
          won't react with most chemicals or oxygen, so it won't tarnish, rust,
          or perish. This characteristic makes it an ideal option for being used
          in high-status items, like fine jewelry, that is meant to last forever
          and maintain its worth and quality.
        </p>

        <p className="text-center col-10 mx-auto">
          The traditional setting for most jewelry, gold is known for its beauty
          and versatility. It's also the most malleable of all metals, and since
          it's so soft, it can't be utilized for jewelry in its pure state.
        </p>

        <p className="text-center col-10 mx-auto">
          Karat refers to the percentage of the gold in the alloy. Typically,
          all gold used to create jewelry, needs to be mixed with other metals
          such and copper, silver, and/or zinc. The purest form of gold that is
          undiluted with any other metal is called 24kt gold which has 100%
          gold.
        </p>

        <p className="text-center col-10 mx-auto">
          Unfortunately, this type of gold is too soft to be used to set any
          gems or diamonds in it. The most commonly used gold purity is 14kt
          Gold which is 58.3% pure gold. But this type of gold is mixed with
          copper, silver and zinc. Depending on whether it is being made into
          white gold, yellow gold or rose gold, the proportions of the alloy
          will change. In the same way, 10Kt gold holds 42% pure gold and the
          rest are base metals mixed into the alloy. 18Kt gold is 75% pure gold
          and the rest are base metals.
        </p>

        <div className="text-center col-10 mx-auto">
          <p className="mb-1">1. 10K = 10/24 = 42% Gold</p>
          <p className="mb-1">14K = 14/24 = 58.3% Gold</p>
          <p className="mb-1">18K= 18/24 = 75% Gold</p>
          <p className="mb-1">22K = 22/24 = 92% Gold</p>
          <p className="mb-1">24K = 24/24 = 100% Gold</p>
        </div>

        {/* Begin :: Types of metal */}
        <Row className="mt-100">
          <Col lg={3}>
            <h2 className="d-lg-none">Yellow Gold</h2>
            <img
              src={imageURL2.image_110}
              className="img-fluid mb-3 mb-lg-0"
              alt="Images"
            />
          </Col>
          <Col lg={9}>
            <h2 className="d-none d-lg-block">Yellow Gold</h2>
            <p>
              Yellow gold was a popular gold color choice in the olden days,
              when white gold was not an option. It's a heavily preferred
              material for jewelry, particularly for engagement and wedding
              rings. This is because it appeals to those who appreciate its rich
              warm color.
              <br />
              <br />
              There are several reasons in choosing yellow gold as a precious
              metal for your jewelry. Firstly it is the original color of pure
              gold. Secondly, it is a great choice for someone looking for a
              warmer tone in their jewelry as well as showing off on a warmer
              skin tone. Yellow gold enhances the color and beauty of gemstones
              such as yellow diamonds, citrines and yellow sapphires.
            </p>
            <button className="btn  btn-black-outline btn-outline-to-secondary mt-2">Browse</button>
          </Col>
        </Row>
        <Row className="mt-5">
          <Col lg={9} className="order-1 order-lg-0">
            <h2 className="d-none d-lg-block">White Gold</h2>
            <p>
              White gold is one of the most popular gold color today, in
              engagement rings and wedding rings. Thai is largely due to the
              fact that it faces up beautifully on lighter skin tones as well as
              darker ones. It is extremely versatile to pair with various
              outfits and can look casual as well as upscale depending on how
              you wear it. <br /> <br />
              In addition, white gold is more malleable than platinum, which
              means that this material can be worked into fine, delicate shapes
              more easily. <br />
              <br />
              Similar to platinum, white gold is made from alloys with one or
              more white metals, like palladium or nickel. It's also less
              expensive than platinum, making it an excellent choice for those
              who want the beauty of platinum, but with the legacy and classical
              style of gold.
              <br />
              <br />
            </p>
            <button className="btn  btn-black-outline btn-outline-to-secondary mt-2">Browse</button>
          </Col>
          <Col lg={3} className="order-0 order-lg-1">
            <h2 className="d-lg-none">White Gold</h2>
            <img
              src={imageURL2.image_111}
              className="img-fluid mb-3 mb-lg-0"
              alt="Images"
            />
          </Col>
        </Row>
        <Row className="mt-5">
          <Col lg={3}>
            <h2 className="d-lg-none">Rose Gold</h2>
            <img
              src={imageURL2.image_112}
              className="img-fluid mb-3 mb-lg-0"
              alt="Images"
            />
          </Col>
          <Col lg={9}>
            <h2 className="d-none d-lg-block">Rose Gold</h2>
            <p>
              Sometimes referred to as pink gold, rose gold is a precious metal
              resulting from gold alloys with a greater copper percentage. It
              can vary in color depending upon the metal alloy’s composition. It
              is popular for many types of jewelry, from engagement rings to
              necklaces.
              <br />
              <br />
              Rose gold is one of the most attractive, lovely, and feminine
              options available for gold jewelry. Its delicate color matches all
              skin tones and adds a timeless elegance to any event or situation,
              formal or casual.
            </p>
            <button className="btn  btn-black-outline btn-outline-to-secondary mt-2">Browse</button>
          </Col>
        </Row>
        {/* <Row className="mt-5">
          <Col lg={9} className="order-1 order-lg-0">
            <h2 className="d-none d-lg-block">Platinum</h2>
            <p>
              Platinum is the most valuable metals used in jewelry. Its value is
              simple to understand; platinum pieces are incredibly durable and
              won't tarnish, ever. <br />
              <br />
              Additionally, platinum is the perfect everyday jewelry due to its
              incredible durability. <br />
              <br />
              Platinum is also naturally hypoallergenic because of its high
              purity level, making it the best option for people with sensitive
              skin. As a result, platinum jewelry is always a good choice.
            </p>
            <button className="btn  btn-black-outline btn-outline-to-secondary mt-2">Browse</button>
          </Col>
          <Col lg={3} className="order-0 order-lg-1">
            <h2 className="d-lg-none">Platinum</h2>
            <img
              src={imageURL.engCat5}
              className="img-fluid mb-3 mb-lg-0"
              alt="Images"
            />
          </Col>
        </Row> */}
        {/* End :: Types of metal */}
      </CustomContainer>
      {/* End:: Discover Collection */}

      {/* Begin:: Metal Table */}
      <CustomContainer>
        <h2 className="text-center fw-bold">Summary of Metals</h2>
        <Col lg="9" className="mx-auto mt-5 table-responsive">
          <table className="table table-bordered">
            <thead className="thead-dark">
              <tr>
                <th></th>
                {/* <th>Platinum</th> */}
                <th>14K Gold (White)</th>
                <th>14K Gold (Rose)</th>
                <th>14K Gold (Yellow)</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Appearance</td>
                {/* <td>
                  A naturally silvery white, platinum is reputed for its
                  everlasting beauty.
                </td> */}
                <td>White gold has a similar appearance to platinum.</td>
                <td>
                  A beautiful alloy of copper and gold, it gives off a pink hue.
                </td>
                <td>
                  Boasting of a rich color for which gold is fairly well-known.
                </td>
              </tr>
              <tr>
                <td>Durability</td>
                {/* <td>
                  Requires minimum maintenance due to its corrosion-resistant
                  body. It never tarnishes, although it can become slightly dull
                  over time due to wear and tear.
                </td> */}
                <td>
                  Sparkling white with a rhodium plating, it may have to be
                  re-plated over time due to wear and tear.
                </td>
                <td>
                  Copper with the alloy strengthens 14K Rose Gold, making it a
                  durable choice.
                </td>
                <td>
                  It includes 58% fine gold with 42% alloys included for
                  strengthening the metal, which makes it resilient for daily
                  wear.
                </td>
              </tr>
              <tr>
                <td>Pricing</td>
                {/* <td>
                  It is the priciest of all the precious metals due to its
                  durability and sparkling appearance.
                </td> */}
                <td>It is more affordable as compared to platinum</td>
                <td>
                  It is more affordable as compared to platinum with the same
                  price point as yellow and white gold.
                </td>
                <td>
                  It’s more reasonable than platinum, and has the same pricing
                  as rose and white gold.
                </td>
              </tr>
            </tbody>
          </table>
        </Col>
      </CustomContainer>
      {/* End:: Metal Table */}

      <CustomContainer className="text-center">
        <h2 className="heading-55 mb-4 text-center fst-italic">Recycled</h2>
        <p className="text-center col-10 mx-auto">
          Gold mining is an unclean industry as unethical and out-of-date as
          diamond mining. Communities are uprooted as a result of gold mining,
          which also pollutes drinking water, harms workers, produces tons of
          garbage, alters landscapes and communities for the worse, and
          contaminates ecosystems with toxic waste, leading to widespread water
          contamination.
        </p>
        <p className="text-center col-10 mx-auto">
          At Andor Luxury, we work only with great quality recycled metals to
          ensure they are well built, but they are produced under ethical and
          environmentally friendly standards.
        </p>

        <a className="btn  btn-black-outline btn-outline-to-secondary" href="/about">
          Read More
        </a>
      </CustomContainer>

      <CustomContainer className="perfact-ring-points">
        <h2 className="text-primary fw-bold text-start mb-3">
          The History of Gold Jewelry
        </h2>
        <p className="mb-3">
          Gold jewelry has a rich and storied history that can be traced back to
          ancient civilizations. The royal tombs of the ancient Egyptian
          Pharaohs, such as the tomb of Tutankhamun, were filled with an
          astonishing array of gold jewelry, indicative of the metal's
          association with divinity and power. Mesopotamia, often called the
          cradle of civilization, produced exquisite gold filigree work dating
          back to 3000 BC. The Romans saw gold as a symbol of status and power.
          Jewelry was not just an adornment but also a display of wealth and
          social standing. Intricate gold necklaces, bracelets, rings, and
          earrings were commonplace among the affluent Roman populace. The Roman
          era also introduced the use of precious gemstones in gold jewelry,
          with emeralds, sapphires, and garnets being particularly favored.
        </p>
        <p className="mb-3">
          The fall of the Roman Empire gave rise to the Byzantine Empire, which
          left an indelible mark on the journey of gold jewelry. During this
          era, goldsmiths employed intricate techniques such as granulation and
          cloisonné enameling to create ornate and lavish designs. The Byzantine
          jewelry pieces often featured religious symbols, reflecting the
          Empire's strong Christian faith, and gemstones like pearls, amethysts,
          sapphires, and garnets were not uncommon. These gemstones added an
          extra layer of opulence to the gold jewelry, enhancing the visual
          appeal. Notably, the jewelry from this era wasn't just for adornment
          but also served as amulets providing protection to the wearer. The
          Byzantine era thus infused new dimensions of spirituality and
          symbolism into gold jewelry.
        </p>
        <p className="mb-3">
          The Renaissance era witnessed a renewed interest in the arts and
          science, and gold jewelry was no exception. With the invention of the
          gold bulino technique, the design and production of jewelry reached
          new heights. Gold pieces created during this period were characterized
          by their detailed engraving, offering a sense of depth and
          three-dimensionality. The popularity of gold jewelry continued to
          grow, and by the Victorian era, famously known for its sentimental
          jewelry, gold was often the metal of choice. Lovers often gifted each
          other gold lockets and brooches, and mourning jewelry made of
          blackened gold became popular after Prince Albert's death. Gold
          jewelry in this era was heavily symbolic, reflecting love, sorrow,
          devotion, or friendship.
        </p>
        <p className="mb-3">
          Today, gold jewelry continues to adapt to changing fashion trends and
          individual preferences, with themes ranging from minimalism to
          maximalism. Modern gold jewelry is often designed with versatility in
          mind, with pieces that can transition from day to night, casual to
          formal.
        </p>
        <p className="mb-3">
          From ancient civilization to contemporary times, gold's allure is
          truly timeless. Each era has left its imprint on gold jewelry,
          transforming it, shaping it, and adding a fresh perspective. Today,
          gold jewelry is not only a fashion statement or a status symbol but
          also a cherished memory, a piece of heritage, and an artistic
          expression.
        </p>
        <p className="mb-lg-5 mb-4">
          The journey of gold is the journey of humanity itself – continually
          evolving, yet rooted in a rich and lustrous past.
        </p>

        <h2 className="text-primary fw-bold text-start mb-3">
          The Evolution of Gold: Weight, Sturdiness, and Value
        </h2>
        <p className="mb-lg-5 mb-4">
          In the realm of gold, there's more than meets the eye. From
          cutting-edge lightweight technology to classic, sturdy gold, each
          variant has its own story to tell. The Andor Luxury Gold Collection
          features only light-weight and solid gold to maintain our commitment
          to overall quality and value. To help inform your purchase decision,
          we’ve included descriptions of these gold types below, as well as an
          exploration of vermey and hollow gold, which are not in our
          collection, but are still important to understand as part of a
          comprehensive guide to gold jewelry.
        </p>

        <h2 className="text-primary fw-bold text-start mb-3">
          Light Weight Gold - Technologically Perfected
        </h2>
        <p className="mb-lg-5 mb-4">
          Lightweight gold represents a contemporary innovation in the world of
          jewelry crafting, blending traditional craftsmanship with advanced
          technologies to create pieces that are not only aesthetically pleasing
          but also notably lighter in weight compared to conventional gold
          jewelry. Here we will delve into the key aspects of lightweight gold,
          exploring its composition, manufacturing techniques, benefits, and
          considerations for consumers.
        </p>

        <h2 className="text-primary mt-70 fw-bold text-start mb-3">
          Composition:
        </h2>
        <p className="mb-3">
          Gold Alloys:Lightweight gold often incorporates alloys, which are
          mixtures of gold with other metals like silver, copper, or palladium.
          These alloys contribute to the reduction in weight while maintaining
          the inherent properties of gold.
        </p>
        <p className="mb-lg-5 mb-4">
          Lower Karat Values: To achieve a balance between durability and weight
          reduction, lightweight gold may have lower karat values compared to
          solid gold. Common karat values include 18k or 14k, indicating the
          percentage of pure gold in the alloy.
        </p>

        <h2 className="text-primary mt-70 fw-bold text-start mb-3">
          Manufacturing Techniques:
        </h2>
        <p className="mb-3">
          Precision Crafting:Advanced manufacturing technologies, such as laser
          cutting and 3D printing, play a pivotal role in the creation of
          lightweight gold pieces. These technologies allow for precision in
          design, enabling intricate patterns and openwork without compromising
          structural integrity.
        </p>
        <p className="mb-lg-5 mb-4">
          Computer-Aided Design (CAD): Designers utilize CAD technology to
          conceptualize and create intricate designs that contribute to the
          lightweight nature of the gold item. This digital approach enhances
          precision and creativity in crafting.
        </p>

        <h2 className="text-primary mt-70 fw-bold text-start mb-3">
          Benefits of Lightweight Gold:
        </h2>
        <p className="mb-3">
          Comfortable Wear:One of the primary benefits of lightweight gold is
          its comfort. The reduced weight makes these pieces ideal for everyday
          wear, minimizing strain on the wearer's skin.
        </p>
        <p className="mb-lg-5 mb-4">
          Affordability: Due to the reduced gold content and innovative
          manufacturing techniques, lightweight gold pieces are often more
          affordable than their solid gold counterparts, making them accessible
          to a broader range of consumers.
        </p>

        <h2 className="text-primary mt-70 fw-bold text-start mb-3">
          Durability and Considerations for Consumers:
        </h2>
        <p className="mb-3">
          Innovative Alloys:While lightweight, these gold pieces are crafted to
          maintain durability and strength. The use of innovative alloys ensures
          that the jewelry remains sturdy and resilient.
        </p>
        <p className="mb-3">
          Consumer Awareness:Consumers should be aware of the karat value of
          lightweight gold items, as lower karat values may impact the color and
          purity of the gold. Understanding the composition of the jewelry
          contributes to making informed purchasing decisions.
        </p>
        <p className="mb-lg-5 mb-4">
          Light-weight gold represents a harmonious blend of tradition and
          technology, offering consumers a modern and comfortable alternative in
          the realm of jewelry. By understanding the composition, manufacturing
          techniques, and benefits of lightweight gold, consumers can appreciate
          the craftsmanship and versatility that this innovative approach brings
          to the world of gold jewelry.
        </p>

        <h2 className="text-primary mt-70 fw-bold text-start mb-3">
          Hollow Gold - A Delicate Dance
        </h2>
        <p className="mb-lg-5 mb-4">
          Hollow gold represents a fascinating and intricate facet of jewelry
          craftsmanship, where skilled artisans employ specialized techniques to
          create pieces with a delicate, paper-thin appearance. Understanding
          the ins and outs of how hollow gold is made, it’s composition, and
          consumer benefits will help inform your decision when considering the
          purchase of gold jewelry.
        </p>

        <h2 className="text-primary mt-70 fw-bold text-start mb-3">
          Manufacturing Techniques:
        </h2>
        <p className="mb-3">
          Intricate Hollow Forms: Craftsmen use specialized tools to create
          intricate hollow forms within gold structures. These forms range from
          geometric patterns to delicate filigree, contributing to the
          lightweight and delicate nature of the final piece.
        </p>
        <p className="mb-3">
          Precision Metalworking:The creation of hollow gold requires precision
          metalworking techniques, often involving the meticulous shaping and
          connecting of thin gold sheets or wires to form the desired structure.
        </p>
        <p className="mb-lg-5 mb-4">
          Filigree and Openwork: Filigree, an ancient metalworking technique, is
          commonly employed in hollow gold crafting. This involves delicately
          twisting or weaving thin metal threads into ornate patterns. Openwork
          designs, characterized by cut-out sections, further reduce the weight
          of the gold piece.
        </p>

        <h2 className="text-primary mt-70 fw-bold text-start mb-3">
          Composition:
        </h2>
        <p className="mb-3">
          Thin Gold Sheets:Hollow gold pieces are crafted using incredibly thin
          gold sheets, creating a lightweight and ethereal appearance. The
          thickness of these sheets can be as minimal as paper, contributing to
          the delicacy of the final design.
        </p>
        <p className="mb-lg-5 mb-4">
          Metal Framework:Some hollow gold pieces may incorporate a metal
          framework within the structure. This framework provides structural
          support while allowing for intricate designs and reducing the overall
          weight of the jewelry.
        </p>

        <h2 className="text-primary mt-70 fw-bold text-start mb-3">
          Benefits of Hollow Gold:
        </h2>
        <p className="mb-3">
          Lightweight and Comfortable:One of the primary benefits of hollow gold
          is its lightweight nature, ensuring a comfortable wearing experience.
          The reduced weight makes these pieces ideal for everyday wear without
          compromising on style.
        </p>
        <p className="mb-3">
          Intricate Designs:The manufacturing techniques used in hollow gold
          allow for the creation of intricate and detailed designs that might be
          challenging to achieve with solid gold. This opens up a world of
          artistic possibilities, from delicate filigree patterns to airy
          openwork designs.
        </p>
        <p className="mb-3">
          Cost-Effective:Hollow gold pieces often use less gold than their solid
          counterparts, making them more cost-effective. This accessibility
          allows a broader range of individuals to enjoy the craftsmanship and
          beauty of gold jewelry.
        </p>
        <p className="mb-3">
          Durability:While hollow gold pieces are delicately crafted, they are
          designed to be durable for regular wear. However, it's essential for
          wearers to handle them with care and avoid activities that may subject
          the jewelry to excessive stress.
        </p>
        <p className="mb-lg-5 mb-4">
          Hollow gold stands as a testament to the artistry and skill of jewelry
          craftsmen who transform thin sheets of gold into breathtaking works of
          wearable art. With its lightweight, intricate designs, and
          cost-effectiveness, hollow gold continues to captivate those who
          appreciate the delicate balance between form, function, and artistic
          expression in the world of fine jewelry.
        </p>

        <h2 className="text-primary mt-70 fw-bold text-start mb-3">
          Vermey Gold - A Surface Beauty:
        </h2>
        <p className="mb-lg-5 mb-4">
          Vermey gold, also known as gold vermeil, is a unique type of gold
          finish that adds a layer of precious metal to the surface of another
          base metal, typically sterling silver. Understanding and exploring its
          specific composition, application, considerations, and how it differs
          from solid gold will inform you as to whether vermey gold is right for
          you.
        </p>

        <h2 className="text-primary mt-70 fw-bold text-start mb-3">
          Composition and Application:
        </h2>
        <p className="mb-3">
          Gold Coating:Vermey gold involves applying a thin layer of gold onto
          the surface of a base metal, commonly sterling silver. This is
          achieved through a process called electroplating or another coating
          technique.
        </p>
        <p className="mb-3">
          Gold Thickness:The gold layer in Vermey gold is generally thicker than
          what is found in gold-plated items. It often exceeds 2.5 microns,
          contributing to a more durable and longer-lasting finish.
        </p>
        <p className="mb-lg-5 mb-4">
          Base Metal:The underlying base metal, usually sterling silver,
          provides a foundation for the gold coating. Sterling silver is chosen
          for its compatibility with the gold layer and its ability to create a
          beautiful contrast.
        </p>

        <h2 className="text-primary mt-70 fw-bold text-start mb-3">
          Benefits of Vermey Gold:
        </h2>
        <p className="mb-3">
          Affordability:Vermey gold provides an affordable alternative to solid
          gold jewelry. The gold coating allows individuals to enjoy the
          luxurious appearance of gold at a fraction of the cost.
        </p>
        <p className="mb-3">
          Versatility:The base metal, often sterling silver, allows for greater
          versatility in design. Vermey gold pieces can feature intricate
          detailing and complex designs, showcasing the craftsmanship without
          the constraints of using solid gold.
        </p>
        <p className="mb-lg-5 mb-4">
          Rich Aesthetic:Vermey gold items boast a rich and lustrous appearance
          akin to that of solid gold. The thickness of the gold layer
          contributes to a warm, golden hue, enhancing the overall aesthetic
          appeal of the jewelry.
        </p>

        <h2 className="text-primary mt-70 fw-bold text-start mb-3">
          Considerations for Consumers:
        </h2>
        <p className="mb-3">
          Wear and Tear:While more durable than traditional gold-plated items,
          Vermey gold can still experience wear over time, especially if
          subjected to friction or harsh chemicals. Care should be taken to
          avoid abrasive materials during cleaning.
        </p>
        <p className="mb-lg-5 mb-4">
          Value Perception:It's essential for consumers to understand that
          Vermey gold, while offering a luxurious appearance, does not hold the
          same intrinsic value as solid gold. The value is derived from the
          craftsmanship, design, and the aesthetic appeal it brings.
        </p>

        <h2 className="text-primary mt-70 fw-bold text-start mb-3">
          Comparison with Solid Gold:
        </h2>
        <p className="mb-3">
          Cost Difference: Vermey gold offers a cost-effective option compared
          to solid gold, making it accessible to a broader audience.
        </p>
        <p className="mb-3">
          Appearance: While Vermey gold mimics the appearance of solid gold, the
          discerning eye may notice differences in the depth of color and the
          richness of the metal.
        </p>
        <p className="mb-3">
          Intrinsic Value: Solid gold maintains its intrinsic value over time,
          whereas Vermey gold is valued for its aesthetic qualities rather than
          its precious metal content.
        </p>
        <p className="mb-lg-5 mb-4">
          Vermey gold represents a fusion of affordability and aesthetic appeal,
          allowing individuals to enjoy the luxury of gold without the hefty
          price tag. As consumers explore Vermey gold jewelry, understanding the
          composition, benefits, and considerations will empower them to make
          informed choices based on their preferences and expectations.
        </p>

        <h2 className="text-primary mt-70 fw-bold text-start mb-3">
          Gold - A True Store of Value:
        </h2>
        <p className="mb-lg-5 mb-4">
          Solid gold stands as a timeless and prestigious material with a rich
          history in the world of jewelry. This explanation aims to provide
          insights into the characteristics, composition, and intrinsic value of
          solid gold, exploring why it remains an enduring symbol of luxury and
          craftsmanship.
        </p>

        <h2 className="text-primary mt-70 fw-bold text-start mb-3">
          Composition:
        </h2>
        <p className="mb-3">
          Pure Gold:Solid gold refers to jewelry made from a high percentage of
          pure gold. Gold is measured in karats, with 24-karat gold being pure
          gold. Solid gold jewelry may range in purity, with common karat values
          being 18k (75% gold), 14k (58.3% gold), and 10k (41.7% gold).
        </p>
        <p className="mb-lg-5 mb-4">
          Alloys:To enhance durability and adjust the color, solid gold is often
          alloyed with other metals such as copper, silver, or palladium. The
          choice of alloys influences the final color of the gold, whether it's
          yellow, white, or rose gold.
        </p>

        <h2 className="text-primary mt-70 fw-bold text-start mb-3">
          Intrinsic Value and Durability:
        </h2>
        <p className="mb-3">
          Wealth and Status:Throughout history, solid gold has been a symbol of
          wealth, status, and prestige. Its rarity, combined with its enduring
          beauty, has made it a sought-after material for crafting fine jewelry.
        </p>
        <p className="mb-3">
          Investment:Solid gold holds intrinsic value as an investment. The
          value of gold tends to remain relatively stable over time, making it a
          popular choice for those looking to preserve and grow their wealth.
        </p>
        <p className="mb-3">
          Resistance to Tarnish and Corrosion:Gold, in its pure form, is
          resistant to tarnish and corrosion. This quality ensures that solid
          gold jewelry maintains its brilliance and luster with proper care.
        </p>
        <p className="mb-3">
          Malleability:Solid gold is highly malleable, allowing craftsmen to
          create intricate and detailed designs. This characteristic is
          particularly advantageous for crafting fine jewelry with delicate
          patterns.
        </p>
        <p className="mb-3">
          Longevity: Solid gold jewelry is known for its longevity. With proper
          care, solid gold pieces can be passed down through generations,
          retaining their beauty and value.
        </p>
        <p className="mb-lg-5 mb-4">
          Solid gold remains the epitome of luxury and timeless elegance. Its
          intrinsic value, combined with its versatility in design and enduring
          appeal, makes it a cherished material for crafting fine jewelry.
          Understanding the composition and qualities of solid gold empowers
          consumers to make informed choices when selecting pieces that reflect
          their style and appreciation for enduring beauty.
        </p>

        <h2 className="text-primary mt-70 fw-bold text-start mb-3">
          Charting Gold's Journey Over 70 Years
        </h2>
        <p className="mb-3">
          Below is a representation of the approximate price trends of gold over
          the past 70 years. Please note that these values are approximate and
          subject to change based on various economic factors:
        </p>
        <div className="table-responsive mx-auto col-md-5 col-lg-3 text-center">
          <table className="table table-bordered">
            <thead className="thead-dark">
              <tr>
                <th>Year</th>
                <th>Average Price of Gold (USD)</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>1950</td>
                <td>$34.72</td>
              </tr>
              <tr>
                <td>1960</td>
                <td>$35.27</td>
              </tr>
              <tr>
                <td>1970</td>
                <td>$36.02</td>
              </tr>
              <tr>
                <td>1980</td>
                <td>$615.00</td>
              </tr>
              <tr>
                <td>1990</td>
                <td>$383.51</td>
              </tr>
              <tr>
                <td>2000</td>
                <td>$279.11</td>
              </tr>
              <tr>
                <td>2010</td>
                <td>$1,224.53</td>
              </tr>
              <tr>
                <td>2020</td>
                <td>$1,770.64</td>
              </tr>
            </tbody>
          </table>
        </div>
        <p className="mb-3">
          This table indicates a general upward trend in the price of gold over
          time, reflecting its growing value as a safe-haven asset. However, it
          also shows significant fluctuations and spikes in price, particularly
          during economic crises or periods of instability. This highlights the
          importance of understanding the factors that influence the price of
          gold.
        </p>
        <p className="mb-lg-5 mb-4">
          As we explore the diverse facets of gold, from lightweight innovations
          to the timeless solidity of pure gold, one thing remains clear –
          gold's allure endures. Whether you choose the feather-light feel of
          modern technology or the robust elegance of traditional gold, each
          piece tells a story of craftsmanship, innovation, and enduring value.
          Embrace the journey and find your perfect gold companion – a piece
          that transcends time and trends.
        </p>

        <h2 className="text-primary mt-70 fw-bold text-start mb-3">
          The Gold Collection at Andor Luxury
        </h2>
        <p className="mb-3">
          Explore the all-gold jewelry collection at Andor Luxury – a
          celebration of opulence, craftsmanship, and unparalleled luxury.Each
          piece in our gold jewelry collection is a testament to the artistry
          and craftsmanshipinherent in our brand while capturing the radiance
          and intrinsic beauty of gold. From timeless classics to contemporary
          masterpieces, our collection offers a diverse range of options to suit
          every style and occasion. Our designers have artfully combined modern
          aesthetics with classic elements, resulting in pieces that transcend
          trends and become enduring staples in your jewelry collection.
        </p>
        <p className="mb-3">
          Discover necklaces that redefine sophistication, reflecting the
          unparalleled beauty of pure gold, indulge in the artistry of our gold
          bracelets, where every link tells a story of refined elegance, elevate
          your look with our breathtaking gold earrings, or make a statement
          with our collection of gold rings.
        </p>
        <p className="mb-lg-5 mb-4">
          At the heart of our Gold Jewelry Collection lies a commitment to
          quality, sophistication, and the enduring allure of pure gold. Each
          piece is more than jewelry; it's a symbol of self-expression, an
          embodiment of your unique style, and a cherished part of your story –
          a story told in brilliance, sophistication, and the timeless elegance
          that only gold can provide.
        </p>

        <h2 className="text-primary mt-70 fw-bold text-start mb-3"></h2>
        <p className="mb-lg-5 mb-4"></p>
      </CustomContainer>
    </>
  );
};

export default RightMetal;
